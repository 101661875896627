.badge-links {
    transition: all 235ms ease-in-out 0s;
}
.badge-links:hover {
    box-shadow: 2px 8px 8px -5px rgba(0, 0, 0, 0.3);
    transform: translate3d(0, 2px, 0);
}

strong {
    text-decoration: underline;
}

.card-body ul li::before {
    content: "+"!important;
}

.card-body p,
.card-body ul {
    line-height: 1.3;
}

.fadeOut{
    opacity:0;
    width:100%;
    height:0;
}

.fadeIn{
    opacity:1;
    width:100%;
    height:100%;
    transition: opacity 235ms ease-in-out 0s;
}

html {
    background-color: var(--main-background);
}

i {
    height: 0.9em;
}

.primary-colour {
    color: var(--primary) !important;;
}

.secondary-colour {
    color: var(--secondary) !important;;
}

.muted-colour {
    color: var(--muted);
}

svg {
    fill: var(--primary);
}

.logo-badge {
    color: var(--main-background) !important;
    opacity: 0.85;
}

.logo-logos {
    margin: 10px;
    font-size: 2.5em;
    opacity: 0.85;
}

.react-tooltip {
    z-index: 99999;
}

.logo-links {
    cursor: pointer;
    background-image: none;
    border: none;
}

.logo-links:hover {
    border: none;
}

a:visited {
    color: var(--secondary) !important;
}

.form-group {
    margin-bottom: 0 !important;
    display: flex;
    align-items: center;
}

.cv-div {
    padding: 0 0.5em;
    margin-top: 90px;
}

.card-title-logo {
    flex: auto;
    margin-bottom: 0;
    align-items: baseline;
}

.home-container {
    text-align: center;
    height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    line-height: 5em;
    margin-top: 4em;
    padding: 0 1em;
}

.footer-text {
    text-align: center;
}

.cv-logos {
    font-size: 1.4em;
    margin-right: 10px;
    color: var(--primary);
}

.paper-switch-2-label,
.paper-switch-2 {
    border: none !important;
}

.dark-mode-logo {
    font-size: 1.2em;
    width: 20px;
    color: var(--muted);
}

.paper-switch-slider {
    opacity: 70%;
}

.bounce {
    animation: bounce 2s ease infinite;
}
@keyframes bounce {
    70% { transform:translateY(0%); }
    80% { transform:translateY(-15%); }
    90% { transform:translateY(0%); }
    95% { transform:translateY(-7%); }
    97% { transform:translateY(0%); }
    99% { transform:translateY(-3%); }
    100% { transform:translateY(0); }
}

.bounce:hover {
    animation: none;
}


.nav-brand {
    display: flex;
    align-items: center;
}

.nav-logo-links {
    font-size: 1.3em;
    scale: 1.5;
    margin-right: 18px;
}

.card-title-container {
    margin-bottom: 0 !important;
}

.card-title {
    text-align: center;
}

.home-logo {
    font-size: 3em;
}

.home-image {
    height: 10em;
}

.logo-badges {
    font-size: 1em !important;
}

.logo-badge-container {
    display: flex;
    align-items: center;
}

.home-dark-mode {
    position: fixed;
    right: 30px;
    top: 25px;
}

.hidden {
    display: none !important;
}

@media only screen and (max-width: 768px) {
    .menubar {
        justify-content: space-around;
        margin-left: 1em;
    }
    .cv-logos {
        display: none;
    }
    h2 {
        font-size: 2rem !important;
    }
    h3 {
         font-size: 1.3rem !important;
     }
    .home-container {
        line-height: 3.5em !important;
        margin-top: 8em;
    }
    .burger {
        scale: 0.8;
        margin-right: 0.2rem !important;
    }
    .cv-div {
        padding: 0;
    }
}

@media only screen and (max-width: 490px) {

    h4 {
        font-size: 1.2rem !important;
    }
}

.lds-ripple {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}
.lds-ripple div {
    position: absolute;
    border: 4px solid #fff;
    opacity: 1;
    border-radius: 50%;
    animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
    animation-delay: -0.5s;
}
@keyframes lds-ripple {
    0% {
        top: 36px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 0;
    }
    4.9% {
        top: 36px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 0;
    }
    5% {
        top: 36px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 1;
    }
    100% {
        top: 0px;
        left: 0px;
        width: 72px;
        height: 72px;
        opacity: 0;
    }
}
